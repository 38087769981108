.terms{
    min-height: 75vh;
}

.terms-container{
    padding: 30px;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 2.155rem;
}

.terms-container h2 {
    font-size: 4rem;
    font-weight: bolder;
    text-align: center;
    background-color: var(--secondary-color);
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 30px;
}

.terms-container h2 strong{
    color: #fff;

}

.terms-container h3{
    font-size: 3rem;
    font-weight: bold;
    margin-top: 30px;
}